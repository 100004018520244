<template>
  <div>
    <h1 class="text-5xl text-center mb-2">Portfolio</h1>
    <h2 class="text-3xl mb-2">Skills</h2>
    <ul class="list-disc list-inside mb-4">
      <li><span class="font-semibold">Programming Languages:</span> Golang, PHP (Laravel), .NET, POSIX Shell, JavaScript (Vanilla & Vue)</li>
      <li><span class="font-semibold">Databases:</span> PostgreSQL, SQLite</li>
      <li><span class="font-semibold">DevOps:</span> Linux, Docker, Terraform, Hashicorp Packer, Kubernetes</li>
      <li><span class="font-semibold">Misc:</span> Email standards (SMTP/DMARC/DKIM/SPF), DNS & DNSSEC, basic X.509 & cryptography knowledge</li>
    </ul>
    <p>
      Skill you're looking for not listed here? No worries, I'm quick at picking up new skills. Send me a message and
      we'll figure something out.
    </p>

    <h2 class="text-3xl mb-2 mt-10">Languages</h2>
    <p>I fluently speak German (my mother tongue) and English. I also know the fundamentals of french.</p>

    <h2 class="text-3xl mb-2 mt-10">Previous Positions</h2>
    <h3 class="text-2xl mb-2">Software Engineer at Artidis</h3>
    <p class="mb-3">In July 2021 I started working at Artidis as a software engineer.</p>
    <h3 class="text-2xl mb-2">Apprenticeship at the Canton of Aargau</h3>
    <p class="mb-3">I'm going to complete my apprenticeship at the canton of Aargau in June 2021. During my
      apprenticeship I was able to work with a broad tech stack ranging from the backend to the frontend.</p>
    <p>I also lead a project to merge various solutions for recurring filesystem operations into a single centralized
      service, saving time and reducing complexity of the internal infrastructure.</p>
  </div>
</template>

<script>

export default {
  name: 'Portfolio'
}
</script>

<style scoped>
</style>
